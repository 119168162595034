:root {
  --background-color: var(--light-bg, #dedede);
  --surface-color: var(--light-card, #fffefc);
  --primary-color: var(--accent-yellow, #FFA500);
  --text-primary: var(--light-text, #2d3748);
  --text-secondary: #666666;
  --error-color: #ff4444;
  --success-color: #00c853;
  --button-hover: #f0f0f0;
  --hd-color: #ff4444;
  --icon-color: rgba(45, 55, 72, 0.7);
  --input-bg: #ffffff;
  --input-border: #e1e1e1;
  --button-bg: var(--primary-color);
  --button-hover: #ff9100;
}

[data-theme='dark'] {
  --background-color: var(--dark-bg, #1E1E1E);
  --surface-color: var(--dark-card, #2d2d30);
  --primary-color: var(--accent-yellow, #FFA500);
  --text-primary: var(--dark-text, #e2e8f0);
  --text-secondary: #a0a0a0;
  --error-color: #ff6b6b;
  --success-color: #00c853;
  --button-hover: #3d3d3d;
  --hd-color: #ff4444;
  --icon-color: rgba(226, 232, 240, 0.7);
  --input-bg: #3d3d3d;
  --input-border: #cfbfbf;
  --button-bg: var(--primary-color);
  --button-hover: #ff9100;
}

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: var(--background-color);
  color: var(--text-primary);
}

.app {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.header {
  padding: 0.25rem;
  text-align: center;
  position: relative;
}

.highlight-button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.2s;
}

.highlight-button:hover {
  transform: translateY(-2px);
}

.main-content {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 0.75rem;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.title {
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 800;
  letter-spacing: -1px;
  font-size: 4.5rem;
  margin-bottom: 1rem;
}

.download-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.url-input {
  background-color: var(--input-bg);
  border: 2px solid var(--input-border);
  color: var(--text-color);
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
}

.url-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.load-button {
  background-color: var(--surface-color);
  color: var(--text-primary);
  border: none;
  padding: 1.2rem;
  font-size: 1.2rem;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.load-button:hover:not(:disabled) {
  background-color: var(--button-hover);
}

.load-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.instructions {
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.3rem;
  margin: 2rem 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}

.error-message {
  color: var(--error-color);
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  background-color: rgba(255, 68, 68, 0.1);
  border-radius: 8px;
}

.video-info {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.video-title {
  text-align: center;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.format-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.download-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 1rem 1.2rem;
  background-color: var(--surface-color);
  border: 1px solid var(--input-border);
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
}

.download-button:hover:not(:disabled) {
  background-color: var(--button-hover);
  border-color: var(--primary-color);
}

.download-icon {
  width: 24px;
  height: 24px;
  color: currentColor;
}

.download-button-content {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  flex: 1;
}

.download-icon-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.type-text {
  margin-left: 5px;
  color: var(--text-secondary);
}

.resolution-badge {
  background-color: var(--input-bg);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 1rem;
  color: var(--text-primary);
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
}

.hd-badge {
  background-color: var(--primary-color);
  color: var(--dark-bg);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
}

.external-icon-wrapper {
  display: none;
}

.download-progress {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: var(--text-primary);
  font-weight: 500;
}

.download-progress::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
  border-radius: 0 0 12px 12px;
  width: var(--progress, 0%);
}

.coffee-button-container {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.buyButton {
  display: inline-flex;
  align-items: center;
  background-color: #FFDD00;
  color: #000000;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-family: 'Cookie', cursive;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.buyButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.coffeeImage {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.coffeeButtonText {
  font-weight: 600;
}

.footer {
  text-align: center;
  padding: 0.5rem;
  background-color: var(--surface-color);
  color: var(--text-secondary);
  font-size: 0.9rem;
  border-top: 1px solid #2d2d30;
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1.4;
}

[data-theme='dark'] .footer {
  border-top: 1px solid var(--input-border);
}

.footer p {
  margin: 0.25rem 0;
}

.privacy-notice {
  margin: 0.25rem 0;
  font-size: 0.85rem;
  color: var(--text-primary);
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
}

.disclaimer {
  margin-top: 0.15rem;
  font-size: 0.8rem;
  opacity: 0.8;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 225px;
  margin: 0 auto 1rem;
  border-radius: 16px;
  overflow: hidden;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.video-thumbnail::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M8 5v14l11-7z'/%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.video-preview {
  margin: 1rem auto;
  max-width: 400px;
  height: 225px;
}

.video-thumbnail-placeholder {
  width: 100%;
  height: 225px;
  background-color: var(--surface-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
  text-align: center;
  padding: 1rem;
}

.placeholder-text {
  max-width: 80%;
}

@media (max-width: 600px) {
  .main-content {
    padding: 0.25rem;
    margin-top: 0;
  }

  .title {
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .video-thumbnail {
    max-width: 100%;
    height: 200px;
    margin: 0.5rem auto;
  }

  .download-form {
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .url-input {
    padding: 0.6rem;
    font-size: 1.1rem;
  }

  .load-button {
    padding: 0.6rem;
    font-size: 1.1rem;
  }

  .video-title {
    font-size: 1.5rem;
    margin: 0.8rem 0;
  }

  .download-button {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }

  .download-icon-wrapper {
    gap: 6px;
  }

  .download-icon {
    width: 16px;
    height: 16px;
  }

  .resolution-badge {
    padding: 3px 8px;
    font-size: 0.75rem;
  }

  .hd-badge {
    padding: 2px 6px;
    font-size: 0.7rem;
  }

  .external-icon-wrapper {
    margin-left: 12px;
    padding-left: 12px;
  }

  .external-icon {
    width: 14px;
    height: 14px;
  }

  .footer {
    padding: 0.25rem;
    font-size: 0.8rem;
  }

  .privacy-notice {
    font-size: 0.75rem;
  }

  .disclaimer {
    font-size: 0.7rem;
  }

  .progress-bar-container {
    height: 3px;
  }

  .download-status-text {
    font-size: 0.8rem;
  }

  .theme-toggle {
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.3rem;
    font-size: 1.2rem;
  }

  .header {
    padding-top: 1.5rem;
  }

  .format-list {
    gap: 0.25rem;
    margin-top: 0.75rem;
  }

  .instructions {
    font-size: 1.1rem;
    margin: 1rem 0;
  }

  .error-message {
    padding: 0.75rem;
    margin: 0.75rem 0;
    font-size: 0.9rem;
  }

  .coffee-button-container {
    top: 12px;
    left: 12px;
  }

  .buyButton {
    padding: 6px 12px;
    font-size: 1rem;
  }

  .coffeeImage {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
}

@media (max-width: 350px) {
  .download-button {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }

  .external-icon-wrapper {
    display: none;
  }

  .quality-info {
    margin-left: 0;
  }

  .video-thumbnail {
    height: 180px;
  }

  .title {
    font-size: 1.75rem;
  }
}

.download-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

.download-progress-text {
  color: var(--text-primary);
  font-weight: 500;
  text-align: center;
}

.download-progress-bar {
  width: 100%;
  height: 4px;
  background-color: var(--input-border);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.download-progress-bar::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--progress, 0%);
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.download-button:disabled {
  opacity: 1;
  cursor: default;
  transform: none;
}

.download-button:disabled:hover {
  transform: none;
  box-shadow: none;
}

.download-progress-bar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.download-button.downloading {
  background-color: var(--surface-color);
  cursor: default;
}

.download-button.downloading:hover {
  transform: none;
  box-shadow: none;
}

.download-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-primary);
}

.download-status-text {
  font-size: 1.1rem;
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
}

.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--input-border);
  z-index: 1000;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  top: 0;
}

.download-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
}

.download-status-text {
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 500;
}

.coffee-widget {
  margin: 0.5rem auto 1.5rem;
  text-align: center;
}

.coffee-link {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  background-color: transparent;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

[data-theme='light'] .coffee-link {
  color: #000000;
  border: 2px solid #000000;
  background-color: transparent;
}

[data-theme='light'] .coffee-link:hover {
  background-color: #000000;
  color: #f3da35;
  border-color: #000000;
}

[data-theme='dark'] .coffee-link {
  color: #f3da35;
  border: 2px solid #f3da35;
  background-color: transparent;
}

[data-theme='dark'] .coffee-link:hover {
  background-color: #f3da35;
  color: #1E1E1E;
  border-color: #f3da35;
}

.coffee-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.coffee-icon {
  width: 22px;
  height: 22px;
  fill: currentColor;
}

.coffee-text {
  font-size: 0.95rem;
  font-weight: 600;
  color: currentColor;
}

/* Mobile styles */
@media (max-width: 600px) {
  .coffee-widget {
    margin: 0.75rem auto 1rem;
  }

  .coffee-link {
    padding: 6px 12px;
  }

  .coffee-icon {
    width: 18px;
    height: 18px;
  }

  .coffee-text {
    font-size: 0.85rem;
  }
}

.footer .coffee-widget {
  display: none;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title-icon {
  width: 24px;
  height: 24px;
  color: var(--text-primary);
}

@media (max-width: 600px) {
  .title-icon {
    width: 20px;
    height: 20px;
  }
}

.download-option {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
}

.share-button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.share-button:hover {
  transform: scale(1.1);
  background: var(--primary-color-dark);
}

.share-button svg {
  width: 1.2rem;
  height: 1.2rem;
}

.download-progress-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.download-progress-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 0.2;
  transition: width 0.3s ease;
}

.download-button-content.downloading {
  position: relative;
  width: 100%;
  height: 100%;
}

.download-status-text {
  position: relative;
  z-index: 2;
  color: var(--text-primary);
  font-weight: 600;
}

.download-button.downloading {
  position: relative;
  overflow: hidden;
}

.tweet-card {
  background-color: var(--surface-color);
  border-radius: 16px;
  padding: 1rem;
  margin: 0.75rem 0 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--input-border);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.tweet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.tweet-user {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.tweet-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--input-border);
  padding: 0.5rem;
}

.tweet-user-info {
  display: flex;
  flex-direction: column;
}

.tweet-name {
  font-weight: 700;
  color: var(--text-primary);
}

.tweet-handle {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.tweet-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tweet-media {
  margin: 1rem -1.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--background-color);
  aspect-ratio: 16/9;
  max-height: 400px;
}

.tweet-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tweet-media-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  position: relative;
}

.play-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  border-left: 20px solid white;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.tweet-stats {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--input-border);
}

.tweet-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.tweet-card-note {
  position: absolute;
  bottom: 8px;
  right: 12px;
  color: var(--text-secondary);
  font-size: 0.7rem;
  font-style: italic;
  opacity: 0.7;
}

@media (max-width: 600px) {
  .tweet-card-note {
    font-size: 0.65rem;
    bottom: 6px;
    right: 10px;
  }
}

.url-input-wrapper {
  position: relative;
  width: 100%;
}

.url-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  width: 1.2rem;
  height: 1.2rem;
  z-index: 1;
}

.url-input {
  padding-left: 3rem !important;
}

.fa-solid--link {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59c-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0c-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606c.648 17.722 3.826 35.527 9.69 52.721c1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96c28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83c-3.701-3.694-7.429-6.564-10.341-8.569a16.04 16.04 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.5 152.5 0 0 1 20.522 17.197M467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37c-58.566 58.892-59.387 154.781.36 214.59a152.5 152.5 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.04 16.04 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569c-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51c27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612c5.864 17.194 9.042 34.999 9.69 52.721c.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.url-clear-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  z-index: 2;
}

.url-clear-button:hover {
  color: var(--text-primary);
}

.url-clear-button svg {
  width: 1.2rem;
  height: 1.2rem;
}

.url-input {
  padding-right: 3rem !important;
}