.theme-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 1.5rem;
  transition: transform 0.2s ease, color 0.2s ease;
  z-index: 100;
}

.theme-toggle:hover {
  transform: scale(1.1);
  color: var(--primary-color);
}

.theme-toggle:focus {
  outline: none;
}

.theme-toggle:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
  border-radius: 4px;
} 